export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $company_id: ID!,
    $company_name: String,
    $primary_ticker: String,
    $primary_exchange: String,
    $secondary_ticker: String,
    $secondary_exchange: String,
    $sa_ticker: String
  ) {
    updateCompany(
      company_id: $company_id,
      company_name: $company_name,
      primary_ticker: $primary_ticker,
      primary_exchange: $primary_exchange,
      secondary_ticker: $secondary_ticker,
      secondary_exchange: $secondary_exchange,
      sa_ticker: $sa_ticker
    ) {
      company_id
      company_name
      primary_ticker
      primary_exchange
      secondary_ticker
      secondary_exchange
      sa_ticker
    }
  }
`;

export const insertCompany = /* GraphQL */ `
  mutation InsertCompany($company_name: String!) {
    insertCompany(company_name: $company_name) {
      company_id
      company_name
      primary_ticker
      primary_exchange
      secondary_ticker
      secondary_exchange
      sa_ticker
    }
  }
`;

export const updateGroupMembership = /* GraphQL */ `
  mutation UpdateGroupMembership(
    $cognito_username: ID!,
    $group_name: String!,
    $group_membership_action: String!
  ) {
    updateGroupMembership(
      cognito_username: $cognito_username,
      group_name: $group_name,
      group_membership_action: $group_membership_action
    )
  }
`;

export const updateNDARequirement = /* GraphQL */ `
  mutation UpdateNDARequirement(
    $cognito_username: ID!,
    $require_nda: Boolean!
  ) {
    updateNDARequirement(
      cognito_username: $cognito_username,
      require_nda: $require_nda
    )
  }
`;

