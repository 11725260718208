import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import Sequences from './sequences/sequences';
import './legacy-etl.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`legacy-etl-tabpanel-${index}`}
      aria-labelledby={`legacy-etl-tab-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && (
        <Box sx={{ height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `legacy-etl-tab-${index}`,
    'aria-controls': `legacy-etl-tabpanel-${index}`,
  };
}

function LegacyEtl() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box className="legacy-etl-container">
      <Typography component="h1" className="legacy-etl-title">
        Legacy ETL
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={currentTab} 
          onChange={handleTabChange} 
          aria-label="legacy etl tabs"
        >
          <Tab label="Sequences" {...a11yProps(0)} disableRipple />
          <Tab label="Servers" {...a11yProps(1)} disableRipple />
          <Tab label="Jobs" {...a11yProps(2)} disableRipple />
          <Tab label="Categories" {...a11yProps(3)} disableRipple />
          <Tab label="Lambda" {...a11yProps(4)} disableRipple />
        </Tabs>
      </Box>
      <Box sx={{ height: 'calc(100% - 48px)' }}>
        <TabPanel value={currentTab} index={0}>
          <Sequences />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Typography variant="h6" sx={{ textAlign: 'center'}}>Servers</Typography>
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Typography variant="h6" sx={{ textAlign: 'center'}}>Jobs</Typography>
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Typography variant="h6" sx={{ textAlign: 'center'}}>Categories</Typography>
        </TabPanel>
        <TabPanel value={currentTab} index={4}>
          <Typography variant="h6" sx={{ textAlign: 'center'}}>Lambda</Typography>
        </TabPanel>
      </Box>
    </Box>
  );
}

export default LegacyEtl;
