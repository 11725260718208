import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, IconButton, Switch, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DataGrid } from '@mui/x-data-grid';
import { generateClient } from 'aws-amplify/api';
import { listAlphaPilotUsers } from '../../../graphql_ap/queries';
import { updateGroupMembership, updateNDARequirement } from '../../../graphql_ap/mutations';
import './users.css';

function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [copiedId, setCopiedId] = useState(null);

  const client = generateClient();

  const handleCopyId = async (id) => {
    try {
      await navigator.clipboard.writeText(id);
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString === 'null') return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'America/Chicago',
      timeZoneName: 'short'
    }).format(date);
  };

  const formatTimeAgo = (dateString) => {
    if (!dateString || dateString === 'null') return 'never';
    
    const date = new Date(dateString);
    const now = new Date();
    const diffSeconds = Math.floor((now - date) / 1000);
    
    if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
    }
    
    const diffMinutes = Math.floor(diffSeconds / 60);
    if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    }
    
    const diffHours = Math.floor(diffMinutes / 60);
    if (diffHours < 24) {
      return `${diffHours} hours ago`;
    }
    
    const diffDays = Math.floor(diffHours / 24);
    return `${diffDays} days ago`;
  };

  const groupColumns = [
    {
      name: 'Transcripts',
      field: 'group_membership_ViewTranscriptAccess',
      groupName: 'ViewTranscriptAccess'
    },
    {
      name: 'Analytics',
      field: 'group_membership_ViewAnalytics',
      groupName: 'ViewAnalytics'
    },
    {
      name: 'Excel',
      field: 'group_membership_ExcelAddinAccess',
      groupName: 'ExcelAddinAccess'
    },
    {
      name: 'Upload',
      field: 'group_membership_UploadTranscriptAccess',
      groupName: 'UploadTranscriptAccess'
    }
  ];

  const handleGroupMembershipChange = async (cognito_username, groupName, newValue) => {
    try {
      const response = await client.graphql({
        query: updateGroupMembership,
        variables: {
          cognito_username,
          group_name: groupName,
          group_membership_action: newValue ? 'add' : 'remove'
        }
      });

      if (response?.data?.updateGroupMembership === 'success') {
        // Refresh data only on successful update
        fetchUsers();
      } else {
        console.error('Unexpected response from group membership update:', response?.data?.updateGroupMembership);
      }
    } catch (error) {
      console.error('Error updating group membership:', error);
      // Refresh data to ensure UI is in sync with backend
      fetchUsers();
    }
  };

  const handleNDARequirementChange = async (cognito_username, newValue) => {
    try {
      const response = await client.graphql({
        query: updateNDARequirement,
        variables: {
          cognito_username,
          require_nda: newValue
        }
      });

      const result = response?.data?.updateNDARequirement;
      if (result === "Success") {
        // Refresh data only on successful update
        fetchUsers();
      } else if (result === "Skipped" || result === "NoAction") {
        console.log(`NDA update ${result.toLowerCase()} for user ${cognito_username}`);
      } else {
        console.error('Unexpected response from NDA update:', result);
      }
    } catch (error) {
      console.error('Error updating NDA requirement:', error);
      // Refresh data to ensure UI is in sync with backend
      fetchUsers();
    }
  };

  const columns = [
    {
      field: 'cognito_username',
      headerName: 'ID',
      width: 55,
      editable: false,
      renderCell: (params) => (
        <Tooltip 
          title={copiedId === params.value ? "Copied!" : "Copy cognito_username"}
          placement="right"
        >
          <IconButton
            onClick={() => handleCopyId(params.value)}
            size="small"
            className={copiedId === params.value ? "copy-button copied" : "copy-button"}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      editable: false
    },
    {
      field: 'email_verified',
      headerName: 'Verified',
      width: 95,
      editable: false,
      renderCell: (params) => (
        <div className="checkmark-cell">
          {params.value === "true" && <CheckCircleIcon style={{ color: '#4caf50' }} />}
        </div>
      )
    },
    {
      field: 'confirmation_status',
      headerName: 'Confirmed',
      width: 110,
      editable: false,
      renderCell: (params) => (
        <div className="checkmark-cell">
          {params.value === "CONFIRMED" && <CheckCircleIcon style={{ color: '#4caf50' }} />}
        </div>
      )
    },
    {
      field: 'agreements',
      headerName: 'Agreement',
      width: 115,
      editable: false,
      renderCell: (params) => {
        const agreements = params.value || [];
        const hasAgreements = agreements.length > 0;
        const formattedDates = agreements.map(formatDate).join('\n');

        return (
          <Tooltip
            title={<span style={{ whiteSpace: 'pre-line' }}>{formattedDates}</span>}
            classes={{ tooltip: 'date-tooltip' }}
            placement="right"
            arrow
          >
            <div className="checkmark-cell">
              {hasAgreements && <CheckCircleIcon style={{ color: '#4caf50' }} />}
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      width: 150,
      editable: false
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      width: 150,
      editable: false
    },
    {
      field: 'nda',
      headerName: 'NDA',
      width: 115,
      editable: false,
      renderCell: (params) => {
        const hasNDA = params.row.nda_accepted_datetime_utc && params.row.nda_accepted_datetime_utc !== "null";
        const ndaDate = hasNDA ? formatDate(params.row.nda_accepted_datetime_utc) : '';

        return (
          <div className="group-switch-cell">
            <Switch
              checked={params.row.nda_required}
              onChange={(e) => handleNDARequirementChange(params.row.cognito_username, e.target.checked)}
              disabled={hasNDA}
            />
            {hasNDA && (
              <Tooltip
                title={ndaDate}
                classes={{ tooltip: 'date-tooltip' }}
                placement="right"
                arrow
              >
                <CheckCircleIcon style={{ color: '#4caf50' }} />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    {
      field: 'most_recent_event_datetime_utc',
      headerName: 'Last Event',
      width: 130,
      editable: false,
      renderCell: (params) => formatTimeAgo(params.value),
      sortComparator: (v1, v2) => {
        // Helper function to get timestamp or MAX_VALUE for null/invalid dates
        const getTimestamp = (value) => {
          if (!value || value === 'null') return Number.MAX_SAFE_INTEGER;
          const date = new Date(value);
          return date.getTime();
        };
        
        const t1 = getTimestamp(v1);
        const t2 = getTimestamp(v2);
        return t1 - t2;
      }
    },
    ...groupColumns.map(group => ({
      field: group.field,
      headerName: group.name,
      width: 115,
      editable: false,
      renderCell: (params) => (
        <div className="group-switch-cell">
          <Switch
            checked={params.value}
            onChange={(e) => handleGroupMembershipChange(params.row.cognito_username, group.groupName, e.target.checked)}
          />
        </div>
      )
    }))
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const result = await client.graphql({
        query: listAlphaPilotUsers
      });
      
      const usersWithIds = result.data.listAlphaPilotUsers.map(user => ({
        ...user,
        id: user.cognito_username
      }));
      
      setUsers(usersWithIds);
      setError(null);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchUsers();
  };

  const clearSearch = () => {
    setSearchText('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      clearSearch();
    }
  };

  const filteredUsers = users.filter(user => {
    const searchLower = searchText.toLowerCase();
    return (
      user.email.toLowerCase().includes(searchLower) ||
      user.first_name.toLowerCase().includes(searchLower) ||
      user.last_name.toLowerCase().includes(searchLower)
    );
  });

  if (error) {
    return (
      <Box className="users-container">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="users-container">
      <Typography component="h1" className="users-title">
        Users
      </Typography>
      <Box className="search-container">
        <TextField
          placeholder="Search users"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleKeyDown}
          size="small"
          className="users-search"
          InputProps={{
            endAdornment: searchText && (
              <IconButton
                aria-label="clear search"
                onClick={clearSearch}
                size="small"
                className="users-search-clear"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
        <IconButton
          onClick={handleRefresh}
          className="users-refresh-button"
          size="small"
          aria-label="refresh data"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Box className="users-grid-container">
        <DataGrid
          rows={filteredUsers}
          columns={columns}
          loading={loading}
          disableRowSelectionOnClick
          hideFooter={true}
          rowHeight={36}
          disableColumnMenu
          disableColumnFilter
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  );
}

export default Users;
