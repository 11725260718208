import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { gridDimensionsSelector } from "../dimensions/index.js";
import { useGridApiEventHandler } from "../../utils/useGridApiEventHandler.js";
export const listViewStateInitializer = (state, props, apiRef) => _extends({}, state, {
  listViewColumn: _extends({}, props.unstable_listColumn, {
    computedWidth: getListColumnWidth(apiRef)
  })
});
export function useGridListView(apiRef, props) {
  /*
   * EVENTS
   */
  const updateListColumnWidth = () => {
    apiRef.current.setState(state => {
      if (!state.listViewColumn) {
        return state;
      }
      return _extends({}, state, {
        listViewColumn: _extends({}, state.listViewColumn, {
          computedWidth: getListColumnWidth(apiRef)
        })
      });
    });
  };
  const prevInnerWidth = React.useRef(null);
  const handleGridSizeChange = viewportInnerSize => {
    if (prevInnerWidth.current !== viewportInnerSize.width) {
      prevInnerWidth.current = viewportInnerSize.width;
      updateListColumnWidth();
    }
  };
  useGridApiEventHandler(apiRef, 'viewportInnerSizeChange', handleGridSizeChange);
  useGridApiEventHandler(apiRef, 'columnVisibilityModelChange', updateListColumnWidth);

  /*
   * EFFECTS
   */
  React.useEffect(() => {
    const listColumn = props.unstable_listColumn;
    if (listColumn) {
      apiRef.current.setState(state => {
        return _extends({}, state, {
          listViewColumn: _extends({}, listColumn, {
            computedWidth: getListColumnWidth(apiRef)
          })
        });
      });
    }
  }, [apiRef, props.unstable_listColumn]);
}
function getListColumnWidth(apiRef) {
  return gridDimensionsSelector(apiRef.current.state).viewportInnerSize.width;
}