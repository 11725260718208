import React, { useState } from 'react';
import { Box, Typography, ThemeProvider } from '@mui/material';
import './legacy-etl-right-panel.css';

function LegacyEtlRightPanel() {

    return (
        <Box className="legacy-etl-right-panel-container">
            <Typography></Typography>
        </Box>
    );
    }

export default LegacyEtlRightPanel;