/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import { REACT_APP_COGNITO_IDENTITY_POOL_ID, REACT_APP_USER_POOLS_ID, REACT_APP_USER_POOLS_WEB_CLIENT_ID } from '@env';

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": REACT_APP_COGNITO_IDENTITY_POOL_ID, //"us-west-2:aa713457-edd9-4e2e-8898-d4d2b4ea0430",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": REACT_APP_USER_POOLS_ID, //"us-west-2_0jwz6yGmr",
    "aws_user_pools_web_client_id": REACT_APP_USER_POOLS_WEB_CLIENT_ID, //"4dumrjq837t35av7rag0kfh5na",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
