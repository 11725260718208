import React, { useState, useEffect } from 'react';
import { Box, Typography, Tooltip, TextField, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SyncIcon from '@mui/icons-material/Sync';
import { generateClient } from 'aws-amplify/api';
import { listSequences } from '../../../../../graphql_ap/queries';
import './sequences-table.css';

function SequencesTable({ onSequenceSelect }) {
    const [sequences, setSequences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchText, setSearchText] = useState('');

    const client = generateClient();

    const formatNumber = (value) => {
        if (value === null || value === undefined) return '';
        return value.toString().padStart(2, '');
    };

    const dayOptions = [null, ...Array.from({ length: 29 }, (_, i) => i)];
    const hourOptions = [null, ...Array.from({ length: 24 }, (_, i) => i)];
    const minuteOptions = [null, ...Array.from({ length: 60 }, (_, i) => i)];

    const handleDayChange = (params) => {
        console.log('Day changed:', params);
        const updatedSequences = sequences.map(seq => {
            if (seq.id === params.id) {
                return { ...seq, scheduled_day_of_month: params.value };
            }
            return seq;
        });
        setSequences(updatedSequences);
    };

    const columns = [
        {
            field: 'sequence_name',
            headerName: 'Name',
            flex: 1,
            minWidth: 170,
            editable: false
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 80,
            editable: false,
            renderCell: (params) => {
                const {
                    is_running,
                    is_stuck,
                    last_duration_result,
                    last_end_utc,
                    last_duration_human,
                    start_utc_if_running,
                    active_job
                } = params.row;

                if (is_stuck) {
                    return (
                        <CancelIcon style={{ color: '#f44336' }} />
                    );
                }

                if (is_running && !is_stuck) {
                    return (
                        <Tooltip
                            title={
                                <React.Fragment>
                                    <div>{start_utc_if_running || 'Running'}</div>
                                    {active_job && <div>{active_job}</div>}
                                </React.Fragment>
                            }
                            placement="right"
                            arrow
                        >
                            <SyncIcon style={{ color: '#2196f3' }} className="rotating-icon" />
                        </Tooltip>
                    );
                }

                if (!is_running && !is_stuck && last_duration_result === "success") {
                    return (
                        <Tooltip
                            title={
                                <React.Fragment>
                                    <div>{last_end_utc}</div>
                                    <div>{last_duration_human}</div>
                                </React.Fragment>
                            }
                            placement="right"
                            arrow
                        >
                            <CheckCircleIcon style={{ color: '#4caf50' }} />
                        </Tooltip>
                    );
                }

                return null;
            }
        },
        {
            field: 'scheduled_day_of_month',
            headerName: 'Day',
            width: 70,
            editable: true,
            type: 'singleSelect',
            valueOptions: dayOptions,
            renderCell: (params) => formatNumber(params.value)
        },
        {
            field: 'scheduled_hour_of_day',
            headerName: 'Hour',
            width: 70,
            editable: true,
            type: 'singleSelect',
            valueOptions: hourOptions,
            renderCell: (params) => formatNumber(params.value)
        },
        {
            field: 'scheduled_minute_of_hour',
            headerName: 'Minute',
            width: 70,
            editable: true,
            type: 'singleSelect',
            valueOptions: minuteOptions,
            renderCell: (params) => formatNumber(params.value)
        }
    ];

    useEffect(() => {
        fetchSequences();
    }, []);

    const fetchSequences = async () => {
        setLoading(true);
        try {
            const result = await client.graphql({
                query: listSequences
            });
            
            console.log('Sequences data:', result.data.listSequences);
            
            const sequencesWithIds = result.data.listSequences.map(sequence => {
                const day = sequence.scheduled_day_of_month;
                return {
                    ...sequence,
                    id: sequence.sequence_name,
                    scheduled_day_of_month: day === null ? null : Number(day)
                };
            });
            
            setSequences(sequencesWithIds);
            setError(null);
        } catch (err) {
            console.error('Error fetching sequences:', err);
            setError('Failed to fetch sequences');
        } finally {
            setLoading(false);
        }
    };

    if (error) {
        return (
            <Box className="sequences-table-container">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const handleRowClick = (params) => {
        if (onSequenceSelect) {
            onSequenceSelect(params.row.sequence_name);
        }
    };

    const clearSearch = () => {
        setSearchText('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            clearSearch();
        }
    };

    const filteredSequences = sequences.filter(sequence => {
        const searchLower = searchText.toLowerCase();
        return sequence.sequence_name.toLowerCase().includes(searchLower);
    });

    return (
        <Box className="sequences-table-container">
            <Box className="search-container">
                <TextField
                    placeholder="Search sequences"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={handleKeyDown}
                    size="small"
                    className="sequences-search"
                    InputProps={{
                        endAdornment: searchText && (
                            <IconButton
                                aria-label="clear search"
                                onClick={clearSearch}
                                size="small"
                                className="sequences-search-clear"
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )
                    }}
                />
            </Box>
            <Box className="sequences-grid-container">
                <DataGrid
                    rows={filteredSequences}
                    columns={columns}
                    loading={loading}
                    hideFooter={true}
                    rowHeight={30}
                    disableColumnMenu
                    disableColumnFilter
                    onRowClick={handleRowClick}
                    onCellEditCommit={handleDayChange}
                />
            </Box>
        </Box>
    );
}

export default SequencesTable;
