import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DataGrid } from '@mui/x-data-grid';
import { generateClient } from 'aws-amplify/api';
import { listCompanies } from '../../../graphql_ap/queries';
import { updateCompany, insertCompany } from '../../../graphql_ap/mutations';
import './companies.css';

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [copiedId, setCopiedId] = useState(null);

  const client = generateClient();

  const handleCopyId = async (id) => {
    try {
      await navigator.clipboard.writeText(id);
      setCopiedId(id);
      setTimeout(() => setCopiedId(null), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const columns = [
    { 
      field: 'company_id', 
      headerName: 'ID', 
      width: 65,
      editable: false,
      renderCell: (params) => (
        <Tooltip 
          title={copiedId === params.value ? "Copied!" : "Copy company_id"}
          placement="right"
        >
          <IconButton
            onClick={() => handleCopyId(params.value)}
            size="small"
            className={copiedId === params.value ? "copy-button copied" : "copy-button"}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )
    },
    { 
      field: 'company_name', 
      headerName: 'Company', 
      width: 300,
      editable: true 
    },
    { 
      field: 'primary_ticker', 
      headerName: 'Ticker 1', 
      width: 120,
      editable: true 
    },
    { 
      field: 'primary_exchange', 
      headerName: 'Exchange 1', 
      width: 150,
      editable: true 
    },
    { 
      field: 'secondary_ticker', 
      headerName: 'Ticker 2', 
      width: 120,
      editable: true 
    },
    { 
      field: 'secondary_exchange', 
      headerName: 'Exchange 2', 
      width: 150,
      editable: true 
    },
    { 
      field: 'sa_ticker', 
      headerName: 'SA Ticker', 
      width: 130,
      editable: true 
    }
  ];

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const result = await client.graphql({
        query: listCompanies
      });
      
      const companiesWithIds = result.data.listCompanies.map(company => ({
        ...company,
        id: company.company_id
      }));
      
      setCompanies(companiesWithIds);
      setError(null);
    } catch (err) {
      console.error('Error fetching companies:', err);
      setError('Failed to fetch companies');
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchCompanies();
  };

  const handleCellEditCommit = async (params) => {
    const { id, field, value } = params;
    const companyToUpdate = companies.find(company => company.id === id);
    
    const updatedCompany = {
      company_id: companyToUpdate.company_id,
      company_name: field === 'company_name' ? value : companyToUpdate.company_name,
      primary_ticker: field === 'primary_ticker' ? value : companyToUpdate.primary_ticker,
      primary_exchange: field === 'primary_exchange' ? value : companyToUpdate.primary_exchange,
      secondary_ticker: field === 'secondary_ticker' ? value : companyToUpdate.secondary_ticker,
      secondary_exchange: field === 'secondary_exchange' ? value : companyToUpdate.secondary_exchange,
      sa_ticker: field === 'sa_ticker' ? value : companyToUpdate.sa_ticker,
    };
  
    try {
      await client.graphql({
        query: updateCompany,
        variables: updatedCompany,
      });
  
      const updatedCompanies = companies.map(company =>
        company.id === id ? { ...company, [field]: value } : company
      );
      setCompanies(updatedCompanies);
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  const handleAddCompany = async () => {
    if (!searchText.trim()) return;
  
    try {
      const result = await client.graphql({
        query: insertCompany,
        variables: { company_name: searchText },
      });
  
      const newCompany = {
        ...result.data.insertCompany,
        id: result.data.insertCompany.company_id,
      };
  
      setCompanies(prevCompanies => [...prevCompanies, newCompany]);
    } catch (error) {
      console.error('Error adding company:', error);
    }
  
    setSearchText('');
  };

  const clearSearch = () => {
    setSearchText('');
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      clearSearch();
    }
  };

  const filteredCompanies = companies.filter(company => {
    const searchLower = searchText.toLowerCase();
    return (
      company.company_name.toLowerCase().includes(searchLower) ||
      company.primary_ticker.toLowerCase().includes(searchLower)
    );
  });

  if (error) {
    return (
      <Box className="companies-container">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="companies-container">
      <Typography component="h1" className="companies-title">
        Companies
      </Typography>
      <Box className="search-container">
        <TextField
          placeholder="Search companies"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={handleKeyDown}
          size="small"
          className="companies-search"
          InputProps={{
            endAdornment: searchText && (
              <IconButton
                aria-label="clear search"
                onClick={clearSearch}
                size="small"
                className="companies-search-clear"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
        <Button
          variant="contained"
          onClick={handleAddCompany}
          className="companies-add-button"
          size="small"
        >
          Add
        </Button>
        <IconButton
          onClick={handleRefresh}
          className="companies-refresh-button"
          size="small"
          aria-label="refresh data"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Box className="companies-grid-container">
        <DataGrid
          rows={filteredCompanies}
          columns={columns}
          loading={loading}
          processRowUpdate={(newRow, oldRow) => {
            const params = {
              id: newRow.id,
              field: Object.keys(newRow).find(key => newRow[key] !== oldRow[key]),
              value: newRow[Object.keys(newRow).find(key => newRow[key] !== oldRow[key])]
            };
            handleCellEditCommit(params);
            return newRow;
          }}
          onProcessRowUpdateError={(error) => {
            console.error('Error processing row update:', error);
          }}
          disableRowSelectionOnClick
          hideFooter={true}
          rowHeight={36}
          disableColumnMenu
          disableColumnFilter
          style={{ width: '100%' }}
        />
      </Box>
    </Box>
  );
}

export default Companies;
