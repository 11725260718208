import React, { useState } from 'react';
import { Box, Typography, ThemeProvider } from '@mui/material';
import KETheme from '../../../miscellaneous/KETheme';
import FlexDivider from '../../../miscellaneous/FlexDivider';
import LegacyEtlLeftPanel from './left-panel/legacy-etl-left-panel';
import LegacyEtlMiddlePanel from './middle-panel/legacy-etl-middle-panel';
import LegacyEtlRightPanel from './right-panel/legacy-etl-right-panel';
import './sequences.css';

function Sequences() {
  const [paneWidths, setPaneWidths] = useState([30, 35, 35]);
  const [selectedSequence, setSelectedSequence] = useState("");

  const onDrag = (e, index) => {
    const newWidths = [...paneWidths];
    const mousePositionPercent = (e.clientX / window.innerWidth) * 100;

    if (index === 0) {
      // If dragging the first divider
      newWidths[0] = mousePositionPercent;
      newWidths[1] = 100 - mousePositionPercent - paneWidths[2];
    } else if (index === 1) {
      // If dragging the second divider
      newWidths[1] = mousePositionPercent - paneWidths[0];
      newWidths[2] = 100 - mousePositionPercent;
    }

    setPaneWidths(newWidths);
  };
    return (
        <ThemeProvider theme={KETheme}>
        <Box className="sequences-container">
        <Box sx={{ display: 'flex', width: '100%', height: 'calc(100% - 40px)', mt: 2 }}>
          <Box width={`${paneWidths[0]}%`} sx={{ height: '100%', overflow: 'auto', flexShrink: 0 }}>
            <LegacyEtlLeftPanel onSequenceSelect={setSelectedSequence}/>
          </Box>
          <FlexDivider onDrag={onDrag} index={0} direction="vertical" />
          <Box width={`${paneWidths[1]}%`} sx={{ height: '100%', overflow: 'auto', flexShrink: 0 }}>
            <LegacyEtlMiddlePanel selectedSequence={selectedSequence}/>
          </Box>
          <FlexDivider onDrag={onDrag} index={1} direction="vertical" />
          <Box width={`${paneWidths[2]}%`} sx={{ height: '100%', overflow: 'auto', flexShrink: 0 }}>
            <LegacyEtlRightPanel/>
          </Box>
        </Box>
        </Box>
        </ThemeProvider>
    );
    }

export default Sequences;
