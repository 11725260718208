import React, { useState } from 'react';
import { View, TextInput, TouchableOpacity, StyleSheet, Text, Linking } from 'react-native';
import { signIn, getCurrentUser } from 'aws-amplify/auth';
import { logUserEvent } from './UserFunctions';
import ForgotPassword from './ForgotPassword';

function SignIn({ onSignIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleSubmit = async () => {
    setError('');

    if (!email.trim() || !password.trim()) {
      setError('Email and password are required.');
      return;
    }

    try {
      console.log('Signing in...');
      const signInResponse = await signIn({
        username: email, 
        password: password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });
      console.log('signInResponse:', signInResponse);
      const currentUser = await getCurrentUser();
      onSignIn(currentUser);
      
    } catch (error) {
      console.error('Error signing in: ', error);
      console.error('Underlying exception: ', error.underlyingException);
      setError('Incorrect email or password. Please try again.');
    }
  };

  if (showForgotPassword) {
    return <ForgotPassword onCancel={() => setShowForgotPassword(false)} />;
  }

  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
        <View style={styles.card}>
          <Text style={styles.title}>Alpha-Pilot Admin</Text>
          <Text style={styles.subtitle}>Employee Login</Text>
          
          {error ? (
            <Text style={styles.error}>{error}</Text>
          ) : null}

          <TextInput
            style={styles.input}
            placeholder="Email"
            placeholderTextColor="#666"
            value={email}
            onChangeText={setEmail}
            autoCapitalize="none"
            keyboardType="email-address"
          />

          <TextInput
            style={styles.input}
            placeholder="Password"
            placeholderTextColor="#666"
            value={password}
            onChangeText={setPassword}
            secureTextEntry
          />

          <TouchableOpacity 
            style={styles.button}
            onPress={handleSubmit}
          >
            <Text style={styles.buttonText}>Sign In</Text>
          </TouchableOpacity>

          <TouchableOpacity 
            style={styles.linkButton}
            onPress={() => setShowForgotPassword(true)}
          >
            <Text style={styles.linkText}>Forgot Password?</Text>
          </TouchableOpacity>

          <View style={styles.publicNotice}>
            <Text style={styles.publicNoticeText}>
              For public access, please visit{' '}
              <Text 
                style={styles.publicLink}
                onPress={() => Linking.openURL('https://alpha-pilot.ai')}
              >
                alpha-pilot.ai
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: '#f0f4f8',
  },
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    padding: 24,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 6,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
    textAlign: 'center',
    color: '#1a73e8',
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 24,
    textAlign: 'center',
    color: '#5f6368',
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 4,
    marginBottom: 16,
    paddingHorizontal: 12,
    fontSize: 16,
    backgroundColor: '#ffffff',
  },
  button: {
    backgroundColor: '#1a73e8',
    padding: 15,
    borderRadius: 4,
    marginTop: 8,
  },
  buttonText: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
  },
  linkButton: {
    marginTop: 16,
    padding: 5,
  },
  linkText: {
    color: '#1a73e8',
    textAlign: 'center',
    fontSize: 14,
  },
  error: {
    color: '#d93025',
    marginBottom: 16,
    textAlign: 'center',
  },
  publicNotice: {
    marginTop: 24,
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: '#eee',
  },
  publicNoticeText: {
    textAlign: 'center',
    color: '#5f6368',
    fontSize: 14,
  },
  publicLink: {
    color: '#1a73e8',
    textDecorationLine: 'underline',
  },
});

export default SignIn;
