import React, { useState } from 'react';
import { Box, Typography, ThemeProvider } from '@mui/material';
import SequencesTable from './sequences-table';
import './legacy-etl-left-panel.css';

function LegacyEtlLeftPanel({ onSequenceSelect }) {

    return (
        <Box className="legacy-etl-left-panel-container">
            <SequencesTable onSequenceSelect={onSequenceSelect} />
        </Box>
    );
    }

export default LegacyEtlLeftPanel;
