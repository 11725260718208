import _extends from "@babel/runtime/helpers/esm/extends";
import * as React from 'react';
import { useThemeProps } from '@mui/material/styles';
import { GRID_DEFAULT_LOCALE_TEXT } from "../constants/index.js";
import { DATA_GRID_DEFAULT_SLOTS_COMPONENTS } from "../constants/defaultGridSlotsComponents.js";
import { computeSlots, useProps } from "../internals/utils/index.js";
import { DATA_GRID_PROPS_DEFAULT_VALUES } from "../constants/dataGridPropsDefaultValues.js";
const DATA_GRID_FORCED_PROPS = {
  disableMultipleColumnsFiltering: true,
  disableMultipleColumnsSorting: true,
  throttleRowsMs: undefined,
  hideFooterRowCount: false,
  pagination: true,
  checkboxSelectionVisibleOnly: false,
  disableColumnReorder: true,
  keepColumnPositionIfDraggedOutside: false,
  signature: 'DataGrid',
  unstable_listView: false
};
const defaultSlots = DATA_GRID_DEFAULT_SLOTS_COMPONENTS;
export const useDataGridProps = inProps => {
  const themedProps = useProps(
  // eslint-disable-next-line material-ui/mui-name-matches-component-name
  useThemeProps({
    props: inProps,
    name: 'MuiDataGrid'
  }));
  const localeText = React.useMemo(() => _extends({}, GRID_DEFAULT_LOCALE_TEXT, themedProps.localeText), [themedProps.localeText]);
  const slots = React.useMemo(() => computeSlots({
    defaultSlots,
    slots: themedProps.slots
  }), [themedProps.slots]);
  const injectDefaultProps = React.useMemo(() => {
    return Object.keys(DATA_GRID_PROPS_DEFAULT_VALUES).reduce((acc, key) => {
      // @ts-ignore
      acc[key] = themedProps[key] ?? DATA_GRID_PROPS_DEFAULT_VALUES[key];
      return acc;
    }, {});
  }, [themedProps]);
  return React.useMemo(() => _extends({}, themedProps, injectDefaultProps, {
    localeText,
    slots
  }, DATA_GRID_FORCED_PROPS), [themedProps, localeText, slots, injectDefaultProps]);
};