export const listCompanies = /* GraphQL */ `
  query ListCompanies {
    listCompanies {
    company_id
    company_name
    primary_ticker
    primary_exchange
    secondary_ticker
    secondary_exchange
    sa_ticker
  }
}
`;

export const listAlphaPilotUsers = /* GraphQL */ `
  query ListAlphaPilotUsers {
    listAlphaPilotUsers {
      cognito_username
      email
      first_name
      last_name
      email_verified
      confirmation_status
      account_status
      groups
      agreements
      nda_required
      nda_accepted_datetime_utc
      group_membership_ExcelAddinAccess
      group_membership_UploadTranscriptAccess
      group_membership_ViewAnalytics
      group_membership_ViewTranscriptAccess
      most_recent_event_datetime_utc
    }
  }
`;

export const listSequences = /* GraphQL */ `
  query ListSequences {
    listSequences {
      sequence_name
      active_job
      is_exclusive
      is_running
      is_stuck
      scheduled_day_of_month
      scheduled_hour_of_day
      scheduled_minute_of_hour
      start_utc_if_running
      last_end_utc
      last_duration_human
      last_duration_seconds
      last_duration_result
    }
  }
`;