import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import './legacy-etl-middle-panel.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`legacy-etl-middle-panel-tabpanel-${index}`}
      aria-labelledby={`legacy-etl-middle-panel-tab-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && (
        <Box sx={{ height: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `legacy-etl-middle-panel-tab-${index}`,
    'aria-controls': `legacy-etl-middle-panel-tabpanel-${index}`,
  };
}

function LegacyEtlMiddlePanel({ selectedSequence }) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box className="legacy-etl-middle-panel-container">
      <Typography variant="h6" className="legacy-etl-middle-panel-title">
        {selectedSequence ? `Sequence: ${selectedSequence}` : ""}
      </Typography>
      {selectedSequence && (
        <>
          <Box>
            <Tabs 
              value={currentTab} 
              onChange={handleTabChange} 
              centered
              sx={{
                minHeight: '35px',
                maxHeight: '35px',
                '& .MuiTab-root': {
                  minHeight: '35px',
                  padding: '5px 5px 5px 5px',
                },
              }}
              aria-label="middle panel tabs"
            >
              <Tab label="Members" {...a11yProps(0)} disableRipple />
              <Tab label="Log" {...a11yProps(1)} disableRipple />
            </Tabs>
          </Box>
          <Box sx={{ height: 'calc(100% - 48px)' }}>
            <TabPanel value={currentTab} index={0}>
              <Typography variant="h6" sx={{ textAlign: 'center', fontSize: '16px', paddingTop: '40px'}}>Members</Typography>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Typography variant="h6" sx={{ textAlign: 'center', fontSize: '16px', paddingTop: '40px'}}>Log</Typography>
            </TabPanel>
          </Box>
        </>
      )}
    </Box>
  );
}

export default LegacyEtlMiddlePanel;
