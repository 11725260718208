import React, { useState } from 'react';
import { View, TextInput, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { confirmResetPassword } from 'aws-amplify/auth';

function ResetPassword({ email, onSuccess, onCancel }) {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [isReset, setIsReset] = useState(false);

  const handleSubmit = async () => {
    setError('');
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword
      });
      setIsReset(true);
      onSuccess();
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(error.message || 'An error occurred. Please check that you are using the most recent code sent by email.');
    }
  };

  if (isReset) {
    return (
      <View style={styles.outerContainer}>
        <View style={styles.container}>
          <View style={styles.card}>
            <Text style={styles.title}>Password Reset Successful</Text>
            <Text style={styles.message}>
              Your password has been reset successfully.
            </Text>
            <TouchableOpacity 
              style={styles.button}
              onPress={onCancel}
            >
              <Text style={styles.buttonText}>Return to Sign In</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.outerContainer}>
      <View style={styles.container}>
        <View style={styles.card}>
          <Text style={styles.title}>Reset Password</Text>
          
          <Text style={styles.message}>
            Please check your email for the verification code.
          </Text>

          {error ? (
            <Text style={styles.error}>{error}</Text>
          ) : null}

          <TextInput
            style={styles.input}
            placeholder="Verification Code"
            placeholderTextColor="#666"
            value={code}
            onChangeText={setCode}
          />

          <TextInput
            style={styles.input}
            placeholder="New Password"
            placeholderTextColor="#666"
            value={newPassword}
            onChangeText={setNewPassword}
            secureTextEntry
          />

          <TouchableOpacity 
            style={styles.button}
            onPress={handleSubmit}
          >
            <Text style={styles.buttonText}>Reset Password</Text>
          </TouchableOpacity>

          <TouchableOpacity 
            style={styles.linkButton}
            onPress={onCancel}
          >
            <Text style={styles.linkText}>Back to Sign In</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: '#f0f4f8',
  },
  container: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: 8,
    padding: 24,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 24,
    textAlign: 'center',
    color: '#1a73e8',
  },
  message: {
    fontSize: 16,
    color: '#5f6368',
    marginBottom: 24,
    textAlign: 'center',
  },
  input: {
    height: 50,
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 4,
    marginBottom: 16,
    paddingHorizontal: 12,
    fontSize: 16,
    backgroundColor: '#ffffff',
  },
  button: {
    backgroundColor: '#1a73e8',
    padding: 15,
    borderRadius: 4,
    marginTop: 8,
  },
  buttonText: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '600',
  },
  linkButton: {
    marginTop: 16,
    padding: 5,
  },
  linkText: {
    color: '#1a73e8',
    textAlign: 'center',
    fontSize: 14,
  },
  error: {
    color: '#d93025',
    marginBottom: 16,
    textAlign: 'center',
  },
});

export default ResetPassword;
