import React, { useState, useEffect, useRef } from 'react';
import { View, TextInput, StyleSheet, Text, Pressable } from 'react-native';

function NavigationBar({ onSelectComponent, onSignOut }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const searchInputRef = useRef(null);

  const components = [
    { id: 'companies', name: 'Companies', component: 'Companies' },
    { id: 'users', name: 'Users', component: 'Users' },
    { id: 'legacy-etl', name: 'Legacy ETL', component: 'LegacyEtl' }
  ];

  const filteredComponents = components.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleSelect = (component) => {
    // console.log('Selected component in NavigationBar:', component);
    onSelectComponent(component);
    setSearchQuery('');
    setShowResults(false);
    setSelectedIndex(-1);
  };

  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!showResults) {
          setShowResults(true);
          setSelectedIndex(0);
        } else {
          setSelectedIndex(prev => 
            prev < filteredComponents.length - 1 ? prev + 1 : prev
          );
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (showResults) {
          setSelectedIndex(prev => prev > 0 ? prev - 1 : prev);
        }
        break;
      case 'Enter':
        e.preventDefault();
        if (showResults && selectedIndex >= 0 && selectedIndex < filteredComponents.length) {
          handleSelect(filteredComponents[selectedIndex]);
        }
        break;
      case 'Escape':
        setShowResults(false);
        setSelectedIndex(-1);
        break;
    }
  };

  return (
    <div style={webStyles.outerContainer}>
      <div style={webStyles.innerContainer}>
        <div style={webStyles.searchWrapper}>
          <div style={webStyles.searchContainer}>
            <input
              ref={searchInputRef}
              style={webStyles.searchInput}
              placeholder="Search components..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setShowResults(true);
                setSelectedIndex(-1);
              }}
              onFocus={() => setShowResults(true)}
              onKeyDown={handleKeyDown}
            />
            
            {showResults && (
              <div style={webStyles.dropdown}>
                {filteredComponents.map((item, index) => (
                  <div
                    key={item.id}
                    style={{
                      ...webStyles.dropdownItem,
                      ...(index === selectedIndex ? webStyles.dropdownItemSelected : {})
                    }}
                    onClick={() => handleSelect(item)}
                    onMouseEnter={() => setSelectedIndex(index)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <button
          style={webStyles.signOutButton}
          onClick={onSignOut}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
}

const webStyles = {
  outerContainer: {
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #dee2e6',
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
    padding: '8px 0',
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  searchWrapper: {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
    padding: '0 16px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  searchContainer: {
    width: '100%',
    position: 'relative',
  },
  searchInput: {
    height: '40px',
    width: '100%',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '0 12px',
    fontSize: '16px',
    backgroundColor: '#ffffff',
    color: '#5f6368',
    boxSizing: 'border-box',
    outline: 'none',
    transition: 'border-color 0.2s',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    boxShadow: 'none',
  },
  dropdown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: 'white',
    border: '1px solid #dee2e6',
    borderRadius: '4px',
    marginTop: '4px',
    zIndex: 1000,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  dropdownItem: {
    padding: '12px',
    borderBottom: '1px solid #dee2e6',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'background-color 0.2s',
    color: '#5f6368',
    fontSize: '16px',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  dropdownItemSelected: {
    backgroundColor: '#f8f9fa',
    color: '#1a73e8',
  },
  signOutButton: {
    backgroundColor: '#1a73e8',
    color: '#ffffff',
    padding: '8px 16px',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    whiteSpace: 'nowrap',
    height: '40px',
    lineHeight: '24px',
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
};

// Add hover effects and focus styles
const style = document.createElement('style');
style.textContent = `
  input:focus {
    border-color: #1a73e8 !important;
    box-shadow: 0 0 0 1px #1a73e8 !important;
  }
  .dropdown-item:hover {
    background-color: #f8f9fa !important;
    color: #1a73e8 !important;
  }
  button:hover {
    background-color: #1557b0 !important;
  }
`;
document.head.appendChild(style);

export default NavigationBar;
